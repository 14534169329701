
@font-face {
    font-family: 'Paper Flowers';
    src: url('../fonts/PaperFlowers.woff2') format('woff2'),
        url('../fonts/PaperFlowers.ttf')  format('truetype'), 
        url('../fonts/PaperFlowers.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block; 
}













