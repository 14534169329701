/*===================
 		COMMUN
 ==================*/
.pi-no-padding{padding:0!important}
li.navItem.visible-980 {display: none;}
.visible-640{display:none}
.margin-10{margin:0 -0.625rem}
.image-mobile{display:none}
@media only screen and (max-width:1680px) {
	.header-sticky{padding:0 0.625rem}
}

@media only screen and (max-width:1560px) {

}
@media screen and (min-width: 1200px) and (max-width: 1365px){
	html  {font-size: 1.15vw;}
}

@media only screen and (max-width:1480px) {
	.onglets>.navItem >.navLink{padding-left: 0.8rem; padding-right: 0.8rem;}
	.sub-product a{font-size: 0.938rem}
	.tel-link strong{font-size: 0.938rem}
	.other-link-top >ul>li{padding-right: 0.625rem; padding-left: 0.625rem}
}

@media only screen and (max-width:1380px) {
	.other-link-top >ul>li{padding-right: 5px; padding-left: 5px}

}
@media only screen and (min-device-width : 1023px) and (max-device-width : 1200px){
	.onglets>.navItem >.navLink{font-size: 0.875rem}
	.sub-product a{font-size: 0.875rem}
	.other-link-top >ul>li{padding-right: 0.625rem; padding-left: 0.625rem}
}
@media only screen and (min-device-width : 1200px) and (max-device-width : 1400px){
	.headerFixed .onglets>.navItem >.navLink{font-size: 0.875rem}
	.headerFixed  .sub-product a{font-size: 0.875rem}
	.headerFixed .other-link-top >ul>li{padding-right: 0.625rem; padding-left: 0.625rem}
}

@media only screen and (max-width:1340px) {
	.logo-site img {width: 100%;}
	.left-logo{width: 230px;}
	.onglets>.navItem >.navLink{padding-left: 0.7rem; padding-right: 0.7rem;}

}
@media screen and (min-width: 1024px) and (max-width: 1200px){
	html {font-size: 1.31vw;}
}
@media only screen and (max-width:1250px) {
	.big-wrapper{  overflow-x: hidden;overflow-y: auto;width: 100%;}

	.container {max-width: 100%;}
	.right-bloc-head {padding: 0;}

	.hidden-mobile-h{display: none;}
	.onglets>.navItem {padding-left: 0;padding-right: 0;}
	.left-logo {width: 160px;padding: 0 5px;}
	.subMenu{margin-top: -2.188rem;padding-top: 77px;}
}
@media (min-width: 991px) and (max-width: 1200px){
	.right-bloc-head {max-width: 100px;}
	.compte-link.link-other {height: 30px;background-size: auto 60px;width: 30px;}
	.compte-link.link-other:hover {background-position: 50% -30px;}


	.panier-link.link-other {height: 30px;background-size: auto 60px;width: 30px;}
	.panier-link.link-other:hover {background-position: 50% -30px;}

}
@media only screen and (max-width:1023px) {
	.absolute-md{display: none;}
	.fixed-devis  span{display: none!important;}
	.fixed-devis a{padding-bottom: 5px;padding-top: 5px;}
}


@media (min-width: 991px) and (max-width: 1080px){
	.onglets>.navItem >.navLink{font-size: 13px}
	.sub-product a{font-size: 0.675rem}
	.onglets>.navItem >.navLink {padding-left: 0.6rem;padding-right: 0.6rem;}
}
@media (min-width: 992px) and (max-width: 1023px){

	.onglets>.navItem >.navLink {padding-left: 0.5rem;padding-right: 0.5rem;}

}
@media only screen and (max-width:1200px) {
	.other-link-top >ul>li  >a span, .other-link-top >ul>li >div>a span{display: none!important;}
	.compte-link.link-other{background-position-x: center;padding-right: 0;width: 30px;}
	.panier-link.link-other{background-position-x: center;padding-right: 0;width: 30px;}
	.logo-site {padding-left: 100px;}
}
@media only screen and (max-width:1023px) {
	.hidden-on-lg{display: none!important;}
}
@media only screen and (min-width:992px) {
	.menu-mob-overlay{display:none!important}
}
.other-lien-menu{display:none}

@media only screen and (max-width:991px) {
	.no-scrolling #box-personnalisation{z-index: 0;}
	#header.zindex_big{z-index: 9999;}
	#header.zindex_big .left-logo, #header.zindex_big  .right-bloc-head,#header.zindex_big  .menu-mobile-bloc , .no-show .left-logo,  .no-show .menu-mobile-bloc, .no-show  .right-bloc-head {opacity: 0;z-index: -1;}
	.zindex_change{z-index: -1;}
	.col-parent-search{    position: fixed;width: 100%;height: 100%; left:auto;right: -500%;top: 0;max-width: 100%;transform: none;background: #fff;z-index: 999;display: flex;align-items: center;padding: 1rem!important;transition: all ease 0.7s;-moz-transition: all ease 0.7s;-webkit-transition: all ease 0.7s;-ms-transition: all ease 0.7s;-o-transition: all ease 0.7s}
	.col-parent-search.open{right: 0;}
	.col-parent-search.open > .center-search{position: absolute;width: 90% !important;top: 75px;}
	.no-scrolling .left-logo, .no-scrolling .right-bloc-head {z-index: 9991;}
	html {font-size: 2.3vw;}
	.panier-deroulant{display: none!important;}
	.contact-header-l{display:none!important}
	.li-m980{display:block}
	.big-wrapper {padding-bottom: 0;}
	.no-scrolling{
		overflow:hidden!important;
		position: fixed;
		height: 100%;
		width: 100%;
		touch-action: none;
		-ms-touch-action: none;
	}
	.logo-site {margin:0; padding: 0}
	.menu-mobile-bloc{display:block}
	.left-logo {text-align:center;}
	.logo-site img {margin: auto;}
	.logo-site a{padding-bottom: 0}
	.slogan{font-size: 12px}

	.mobile-link em{display: none;}
	.other-link-top a.link-other{position:relative}
	.hidden-mobile{display:none}
	.visible-mobile{display:block}
	/********Menu******/
	.navigation-site {display: block;position: fixed;left: 0;background-color: #ffffff;left: -500%;top: 0;height: 100%;z-index: 999;width: 100%;padding-top:50px;transition: all ease 0.7s;-moz-transition: all ease 0.7s;-webkit-transition: all ease 0.7s;-ms-transition: all ease 0.7s;-o-transition: all ease 0.7s;padding-bottom: 0;padding-left: 0!important;padding-right: 0!important;}
	.navigation-site >.scroll-me-mobile_ {height: 100%;background: none;overflow-x: hidden;overflow-y: auto;position: relative!important;padding:0!important}
	.navigation-site .mCSB_inside>.mCSB_container {margin-right: 0.938rem!important;}
	.navigation-site.open-mob {left: 0;transition: all ease 0.7s;-moz-transition: all ease 0.7s;-webkit-transition: all ease 0.7s;-ms-transition: all ease 0.7s;-o-transition: all ease 0.7s;}
	.navigation-site .menu{background: #ffffff;padding-left:25px!important;padding-right: 25px!important;padding-top: 5px;padding-bottom: 5px;border-top: 1px solid rgba(179,179,179,0.75);}
	.navigation-site .onglets {display: block;border-top:1px solid #fff; max-width:100%; padding-left: 0px; padding-right: 0px}
	.close-menu-mob{display:block}
	.span-menu-mob{display:block}

	.navigation-site .onglets>.has-sub >.navLink::after {content: '\e8cc'; position: absolute;right:0;left:auto;font-family:'fontello';top: 50%;font-size: 18px;transition: all ease 0.5s;-webkit-transition: all ease 0.5s;-ms-transition: all ease 0.5s;-o-transition: all ease 0.5s;-moz-transition: all ease 0.5s;background:none; display:block!important; transform:translate(0,-50%); -webkit-transform:translate(0,-50%); -ms-transform:translate(0,-50%);-spec-transform: translate(0, -50%);color: #F98A8A;}
	.navigation-site .onglets>.active> .navLink::after {content:'\e8ca';transition: all ease 0.5s;
		-webkit-transition: all ease 0.5s;-ms-transition: all ease 0.5s;-o-transition: all ease 0.5s;-moz-transition: all ease 0.5s;}
	.subMenu {position: fixed;margin-top: 0;padding:0;border-bottom: none;width: 100%;top: 0;z-index: 999999;height: 80%;left: initial;;right: -500%;transition: all ease 1.5s;-moz-transition: all ease 1.5s;-webkit-transition: all ease 1.5s;-ms-transition: all ease 1.5s;-o-transition: all ease 1.5s;display: block !important; overflow: hidden;border: none; transform: none;-webkit-transform: none;-ms-transform: none;-o-transform: none;}
	.ss-menuniv2 {padding-left: 0.938rem;}


	.sub-menu-top, .sub-menu-top .container, .sub-menu-top .container .row, .flex-categ, .box-flex, .ss-categ-l{height: 100%;}
	.sub-product{overflow-x: hidden;overflow-y: auto;padding-left: 0; }
	.remove_mob_parent.navigation-site .box-flex {overflow-y: auto;padding-left: 25px; padding-right: 25px}
	.sub-menu-top {padding-top: 0.625rem;padding-bottom: 0.625rem; max-width: 100%; padding-left: 0; padding-right: 0}

	.subMenu.open {right: 0;transition: all ease 0.6s;-moz-transition: all ease 0.6s;-webkit-transition: all ease 0.6s;-ms-transition: all ease 0.6s;-o-transition: all ease 0.6s;height: 100%;}
	.navigation-site.open-mob.remove_mob_parent .scroll-me-mobile_ {overflow: hidden;}

	.onglets>.navItem{display:block;padding:0}
	.onglets>.navItem.visible-980 {display: block;}
	.onglets>.navItem>.navLink{color:#000000; text-align:left; padding:1.25rem 15px 25px 0;border-bottom: 1px solid rgba(179,179,179,0.75); font-size:1rem; max-width: 100% ;border-top:none!important; border-left:none!important;border-right:none!important;display: block;}
	.onglets>.navItem:last-child>.navLink{border-bottom: none;}


	.onglets>.navItem.active>.navLink,.onglets>.navItem:hover>.navLink {color: #F98A8A;border-bottom-color:#F98A8A;}
	.hidden-980{display:none}
	.sub-product li{padding-bottom: 0;}
	.sub-product a{color:#000000; text-align:left; padding:1.25rem 15px 1.25rem 0;border-bottom: 1px solid rgba(179,179,179,0.75); font-size:1rem; max-width: 100% ;border-top:none!important; border-left:none!important;border-right:none!important;display: block;}
	.sub-product li:last-child>a{border-bottom: none;}
	.hidden-logo{display:inline-block}
	.visible-logo{display:none}
	.no-scrolling .link-mobile-fixed{display:none}
	.hidden-on-lg{display: none!important;}
	.right-bloc-head{max-width: max-content}
	.other-link-top {max-width:175px;}
	.other-link-top >ul>li{padding-left: 10px; padding-right: 10px;}
	.nbre-panier{font-size: 14px;}
	.sub-product h2{margin-bottom: 0;}

}

@media (max-width: 991px){
	.search-icon {background: url("../images/ico_recherche.svg")  no-repeat 50% 0; background-size: auto 3.5rem !important;}
}

@media (min-width: 768px) and (max-width: 991px){
	html {font-size: 2vw;}
}

@media only screen and (max-width:767px) {
	.hidden-on-md{display: none!important;}
	.link-mobile-fixed {display: block;}
	.hidden-767{display:none}

	.margin-10.visible-980 {display: none !important;}
	.box-flex.bloc-ong-categ .sub-product li {width: 100%;}
	.rech-mobile {position: relative;margin-top:8px}
	.mobile-link em{font-size:11px;}
	.link-mobile-fixed a img{max-width: 40px; }
	.panier-link.link-other {height: 1.875rem;background-size: auto 3.75rem;width: 2.25rem;}
	.panier-link.link-other:hover{background-position: 50% -1.875rem;}
	.compte-link.link-other, .search-link-mobile{height: 1.875rem;background-size: auto 3.75rem;width: 1.875rem;}
	.compte-link.link-other:hover{background-position: 50% -1.875rem;}
	.rech-link.link-other{height: 1.5rem;background-size: auto  3.275rem;width: 1.875rem;}
	.rech-link.link-other:hover{background-position: 50% -1.6rem;}
	.ariane-box *{font-size: 0.7rem;}
}

@media only screen and (max-width:600px) {

	.menu-mobile{padding-right:0.625rem}
	.texte-promo{font-size:12px}

	.other-link-top .lib-other{font-size:11px}

	.hidden-640 {display:none!important}

	.logo {margin-top:0;padding:0;margin-left: -0.625rem;}
	.logo-site{margin-left: -35%;}
	.logo-site a{padding-bottom: 0}
	.visible-640{display:block}
}

@media only screen and (max-width:575px) {
	html {font-size: 3.75vw;}
	.ariane-box *{font-size:0.813rem;}
	#header{overflow: hidden;padding-left: 0.625rem;padding-right: 0.625rem;}
	.other-link-top >ul>li{padding-right: 0.125rem; padding-left: 0.125rem;}
	.right-bloc-head {max-width: max-content}
	.other-link-top {max-width:max-content}
	.left-logo{padding-left: 70px!important;padding-right: 5rem!important;}

	.nbre-panier {font-size: 0.75rem;width: 1.4rem;background-size: 100%;height: 1.875rem;top: -0.4rem;padding-top: 0.15rem;}

}
@media only screen and (max-width:450px) {
	html {font-size: 4.25vw;}
	/*.nbre-panier {padding-top: 0.5rem;}*/
	.fixed-devis{width:125px;}
	.fixed-devis a{padding-left: 5px;}
	.bg-ico-fixed{font-size: 20px;background-size: 22px;padding-left: 27px;}
	.logo-site img {max-width: 8.817rem;}
}

@media only screen and (max-width:420px) {
	.menu-mobile-bloc a{max-width: 50px}
	.navigation-site .menu {padding-left: 20px!important;padding-right: 20px!important;}
	.onglets>.navItem>.navLink{font-size: 0.938rem;}
	.fixed-devis{width:120px;}
	.bg-ico-fixed{font-size: 19px;background-size: 20px;padding-left: 25px;}

}

@media only screen and (max-width:390px) {
	.menu-mobile-bloc a{max-width: 45px;}
	.fixed-devis{width:110px;}
	.bg-ico-fixed{font-size: 17px;background-size: 17px;padding-left: 22px;}
}

@media only screen and (max-width:370px) {
	.other-link-top >ul>li {padding-left: 8px;}
	.menu-mobile-bloc a{max-width: 40px;}
	.onglets>.navItem>.navLink{font-size: 0.938rem;}
	.logo-site {margin-left: -31%;} 
	.logo-site img{max-width: 12rem;}
	.nbre-panier {padding-top: 4px;font-size: 0.75rem;}
	.left-logo {padding-left: 60px!important;padding-right: 5.725rem!important;}
}
@media only screen and (max-width:340px) {
	.other-link-top >ul>li {padding-left: 5px;}
	.logo-site img {max-width: 11rem;}
}
/*===================
 	SLIDER
 ==================*/
@media only screen and (max-width:1100px) {

}
@media only screen and (max-width:991px) {
	.max-container-top .swiper-pagination{padding-bottom: 0}
	.bloc-slider{padding: 0;}
	.conteneur-slider{padding: 0; margin: 0;}
	/*.slider-item.swiper-slide img {opacity: 0;max-width: 150%;width: 150%;}*/
}
@media only screen and (max-width:992px) {
	.home-prev{display: none;}
	.home-next{display: none;}
}
@media only screen and (max-width:767px) {
	.contenu-txt-banner .h1-slider {font-size: 2rem;}
	.phrase-admin * {font-size: 0.8rem !important;}
	
	.conteneur-slider .just-one  .swiper-pagination-home{display: none!important;}
}
@media only screen and (max-width:450px) {
	.slider-item {height: 325px;}
	.slider-item.swiper-slide img {width: 100%;height: 100%;object-fit: scale-down;max-height: 300px;}
}
@media only screen and (max-width:430px) {

	.phrase-admin{font-size: 0.813rem}
}
@media only screen and (max-width:375px) {

	.phrase-admin{font-size: 12px}
}




/*===============================
		ABOUT US
================================*/
@media only screen and (max-width:1200px) {

}
@media only screen and (max-width:1080px) {
	.max-container-1{max-width: 100%; padding-left: 0.938rem; padding-right: 0.938rem}
	.font-20{font-size: 1rem!important;}
}
@media only screen and (max-width:991px) {
	.sofiaregular-16{font-size: 0.875rem}
	.text-regular-onlg{font-family: "sofia-pro"; font-weight: 400;}
	.title-small-mobile{font-size:1.8rem ;}
	.title-medium {font-size: 2.9rem;line-height: 100%;}
}
@media only screen and (max-width:767px) {
	.title-medium{font-size: 2.1rem;}
	.title-ss-regular{font-size: 2.8rem;color: #8A6F90;}
	.remise-article .title-ss-regular{font-size: 2.325rem;}
	.title-small-mobile{font-size:1.4rem ;}
	.title-small-mobile br{display: none;}
	.title-big-regular{font-size: 1.8rem;}
	.fontbold-mobile{font-family: "sofia-pro"; font-weight: 700;}
	.img-about img{max-width: 75px}
	.fontsize-mobile-text{font-size: 1rem}
	.bloc-about .fontsize-mobile-text h2{font-size: 1rem}
	.fontsize-mobile-textbig{font-size: 19px}
	.text-gris-onlg{color: #4D4D4D;}
	.margin-n1{margin-right: -0.938rem; margin-left: -0.938rem}
	.hover-img-scale{font-size: 1rem}
	.d-none-mobile{display: none;}
	.object-fit-video {min-height: 17rem;}
	.bloc-about .object-fit-img{max-height:17rem ;overflow: hidden;}
	.btn-after-style {width: 4rem;height: 3rem;padding-top: 0.7rem;}
	.font-seize{font-size: 0.9rem!important;}
	.font-seize-mobile{font-size: 0.825rem!important;}

}
@media only screen and (min-width:576px) {
	.cartw-editbtn-mobile {display: none !important;}
}

@media only screen and (max-width:575px) {
	.btn-link{font-size: 0.745rem;padding-top: 0.425rem; padding-bottom: 0.425rem;}
	.btn-link.other-radius-btn{font-size: 0.875rem;}
	.title-medium {font-size: 2rem;}
	.title-ss-regular{font-size: 2.625rem;}
	.remise-article .title-ss-regular{font-size: 2.125rem;}
	.title-medium.big-onmobile{font-size: 2.095rem;}
	.title-big-regular{font-size: 1.875rem;}
	.title-small-mobile{font-size:1.6rem ;}

	.font-seize{font-size: 1rem!important;}
	.font-seize-mobile{font-size: 0.875rem!important;}
	.panier-summary-bloc .font-seize-mobile{font-size: 0.8rem!important;}
	.fontsize-mobile-text{font-size: 0.925rem}
	.bloc-about .fontsize-mobile-text h2{font-size: 0.925rem}
	.cartw-editbtn-mobile .btn-link.font-mini-btn {font-size: 0.8rem;padding-top: 16px;padding-bottom: 15px;}
	.body-sylius_shop_cart_summary .visible-only-cart.cartw-editbtn-desktop {display: none !important;}

	.wrap_btn_to.middle{
		flex-grow: 1;
	}
	.wrap_btn_to{
		width: auto;
	}
}
@media only screen and (max-width:430px) {
	.btn-link{font-size: 0.7rem;}
}

@media only screen and (max-width:370px) {
	.fontsize-mobile-text{font-size: 0.875rem}
	.bloc-about .fontsize-mobile-text h2{font-size: 0.875rem}
	.fontsize-mobilesm-text{font-size: 0.875rem!important}
	.max-container-1{padding-right: 0.5rem; padding-left:0.5rem}
	.margin-n1{margin-right: -0.5rem; margin-left: -0.5rem}
	.btn-link{font-size: 0.813rem;padding-left: 0.625rem;padding-right: 0.625rem;}
	.hover-img-scale{font-size: 0.875rem}
	.sofiaregular-16{font-size: 0.875rem}
	.cartw-editbtn-mobile .btn-link.font-mini-btn{padding-top: 14px;padding-bottom: 13px;}
}
@media only screen and (max-width:340px) {
	.fontsize-mobile-text{font-size: 0.813rem}
	.bloc-about .fontsize-mobile-text h2{font-size: 0.813rem}
	.fontsize-mobile-textbig{font-size: 1rem}
	.fontsize-mobilesm-text{font-size: 0.813rem!important}
	.hover-img-scale{font-size: 0.813rem}
	.sofiaregular-16{font-size: 0.813rem}
	.sofiaregular-20{font-size: 1rem}
}

/*===============================
		SELECTION INDEX
================================*/
@media only screen and (max-width:575px) {
	.btn-link.minw-sm {min-width: 8.5rem;}
	.btn-link.minw-sm.minw-auto-mobile{min-width: 4rem;}
	.category-item-lib{font-size: 1.05rem;}
	.category-item-index ul li a span{font-size: 0.935rem;}
	.category-item-index{font-size: 0.825rem;}
	.category-item-index .category-item-desc {display: none;}
	.category-item-index ul {flex-direction: column;}
	.category-item-index ul li {width: 100%; max-width: 100%;}
}

/*===============================
		COLLECTION INDEX
================================*/
@media only screen and (max-width:1180px) {
	.has-bg-chouette {background-size: 23%;}
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1025px){
	.has-bg-chouette .title-medium{font-size: 3rem;}
}
@media only screen and (max-width:767px) {
	.has-bg-chouette {background-size: 70px;}
	.has-bg-chouette .title-medium{padding-left: 20px;padding-right: 20px;}
}
@media only screen and (max-width:360px) {
	.has-bg-chouette {background-size: 60px;}

}
/*===============================
		INSPIRATION INDEX
================================*/
@media only screen and (max-width:991px) {
	.second-col .col-lg-3:nth-last-child(2), .second-col .col-lg-3:last-child {display: none;}
}

@media only screen and (max-width:767px) {
	.second-col .col-lg-3:nth-last-child(4), .second-col .col-lg-3:nth-last-child(3) {display: none;}
}
@media only screen and (max-width:575px) {
	.second-col .col-lg-3:nth-last-child(5), .second-col .col-lg-3:nth-last-child(6) {display: none;}
}

/*===================
 		TOP
 ==================*/
.visible-ml{display:none}
@media only screen and (min-device-width : 992px) and (max-device-width : 1600px){

	.contenu-art-top.position-relative.px-perso-top {max-width: 93%;margin-left: auto;margin-right: auto;}
	.padding-visible-top::before{left: 50px;width: 100px;}
	.btn-slide-next{right: 20px;}
	.btn-slide-prev{left: 20px;}
}
@media only screen and (max-width:1300px) {
	.prev-top{left: 2px;height: 50px;background-size: auto 100px;width: 24px;}
	.next-top{right: 2px;height: 50px;background-size: auto 100px;width: 24px;}
}

@media only screen and (max-width:1200px) {
	.swiper-pagination{position:relative;bottom:0!important; padding:0.813rem 0}
	.bloc-top-content {padding: 0;}
	.visible-ml{display:block}
	.articleBit-lib{line-height: normal;}
	.content-ajout-panier{padding-right: 8px;}
	.btn-ajout-panier{font-size: 0.875rem}
	.input-qte-a{width: 30px}
}
@media only screen and (max-width:1023px) {
	.title-top-content {padding: 0.938rem 0}
	.articleBit-prix{font-size:0.875rem;}
	.price-promo{font-size: 1.2rem;}
	.px-lg-3p.mb-lg-5p {padding-left: 0.7rem!important;padding-right: 0.7rem!important;}
	.mx-n3.mx-lg-n3p{margin-left: -0.7rem!important;margin-right: -0.7rem!important;}
}

@media only screen and (max-width:991px) {
	.swiper-pagination-bullet {margin: 0 6px !important;    width: 0.625rem;height: 0.625rem;}
	.parent-search{position: fixed;bottom: 60px;left: 0;width: 100%;padding: 0.625rem;background: #000;transition: all 0.3s;-webkit-transition: all 0.3s;-moz-transition: all 0.3s;-o-transition: all 0.3s;
		-ms-transition: all 0.3s;opacity: 0;visibility: hidden;transform: translateY(0.625rem);-webkit-transform: translateY(0.625rem);-ms-transform: translateY(0.625rem);-o-transform: translateY(0.625rem);-spec-transform: translateY(0.625rem);z-index: 999;}
	.open-parent.parent-search {opacity: 1;visibility: visible;transform: translateY(0);-webkit-transform: translateY(0);-ms-transform: translateY(0);-o-transform: translateY(0);-spec-transform: translateY(0);}
	.search-bottom {padding: 0 5px;background: #fff;}
	.search-top{max-width: 100%;}
	.s-submit{right: 5px;}
	.btn-slide-next{display: none;}
	.btn-slide-prev{display: none;}
	.padding-visible-top{margin: 0;padding: 0;}
	.contenu-art-top.px-perso-top{padding: 0;}
	.padding-visible-top::before{display: none;}
	.padding-visible-top::after{display: none;}
	.contenu-art-top.px-perso-top .swiper-container{padding-left: 0;padding-right: 0;}
}
@media only screen and (max-width:767px) {
	.articleBit-price{font-size: 0.725rem;}
	.articleBit-state{font-size: 0.625rem;display: none!important;}
	.articleBit-caract-color{display: none!important;}
	.price-promo{font-size: 0.825rem;}
	.prev-top, .next-top{display:none}
	.etiquette.etiquette-3 span {background-size: 12px;padding-left: 1rem;}
	.picto-promo strong{ font-size: 0.938rem;}
	.parent-search{bottom: 50px;}
	.select-container .swiper-pagination-bullet{width: 11px; height: 11px;margin: 0px 7px!important;}
	.px-mob-0{padding-left: 0!important;padding-right: 0!important;}
	.articleBit{padding-bottom: 0;}
	.btn2-slide-next, .btn2-slide-prev {margin-top: -2px;}
}
@media only screen and (max-width:575px) {

	.picto-promo strong{ font-size: 0.813rem;}
	.picto-promo{width: 50px}

	.padding-top-index {padding-left:0px;padding-right: 0;}
	.px-perso-top{padding-left:0px;padding-right: 0px;}
	.mx-xs-n2{margin-right: -0.938rem!important; margin-left: -0.938rem!important;}
	.px-xs-2 {padding-right: 0.938rem!important; padding-left: 0.938rem!important;}
	.articleBit{padding: 0.525rem 0.525rem 0 0.525rem;}
	.pt-perso-10{padding: 0.525rem 0.525rem 0 0.525rem;}
	.etiquette-article{font-size: 11px;}
	.price-promo {font-size: 1.3rem;}
	.articleBit-price{font-size: 0.8rem;}
	.articleBit-state{font-size: 0.725rem;}
}

@media only screen and (max-width:420px) {
	.parent-search {bottom: 46px;}
	.etiquette{font-size: 12px}
	.etiquette.etiquette-3 span {background-size: 0.625rem;padding-left: 0.938rem;}
	.prix-promo{font-size: 22px;}
	.prix-promo small {font-size: 0.813rem;}

	.articleBit-caract{font-size: 0.813rem}
	.mx-xs-n2{margin-right: -0.625rem!important; margin-left: -0.625rem!important;}
	.px-xs-2 {padding-right: 0.625rem!important; padding-left: 0.625rem!important;}
}

@media only screen and (max-width:380px) {

	.articleBit-prix{font-size:0.875rem;}
	.prix-promo{font-size: 20px;}
	.prix-promo small {font-size: 12px;}
	.picto-promo {width: 45px}
	.articleBit-caract{padding-top: 4px; padding-bottom: 4px}
	.picto-promo strong{ font-size: 12px;}

	.articleBit-caract{font-size: 12px}
	.mx-xs-n2{margin-right: -8px!important; margin-left: -8px!important;}
	.px-xs-2 {padding-right: 8px!important; padding-left: 8px!important;}

}
@media only screen and (max-width:340px) {
	.etiquette-article{font-size: 10px;}
	.mx-xs-n2{margin-right: -5px!important; margin-left: -5px!important;}
	.px-xs-2 {padding-right: 5px!important; padding-left: 5px!important;}
	.articleBit-prix{font-size:12px;}
	.prix-promo{font-size: 18px;}
	.prix-promo small {font-size: 11px;}
	.select-container .swiper-pagination-bullet{width: 8px; height: 8px;margin: 0px 5px!important;}
	.etiquette{font-size: 11px}
	.articleBit-caract{font-size: 11px}
}

/**===================================
				FOOTER
=====================================*/
.toogle-menu2-mob{display:none}
@media only screen and (max-width: 1023px){
	.title-menu-t{font-size: 1rem;}
}
@media only screen and (max-width: 991px){
	.padding-reinsurance{padding-top:0; padding-bottom: 1rem;}
	.menu-content{margin-bottom: 0.938rem}
	.box-av{font-size:0.75rem;}
	.bloc-newsletter {font-size: 17px;line-height: 20px;}

	.menu-content.paiement-footer-perso {max-width: 100%;text-align: center;}
	.max-w-paiement{max-width: 400px; margin: auto;}
	.txt-newsletter{font-size: 1rem;}
	.py-perso-news {padding-top: 1rem;padding-bottom: 1rem;}
}

@media only screen and (max-width: 767px){
	.bg-fixed-reseau{background-position: center top!important; height: 22rem;}
	.box-av{text-align: center;font-size: 0.825rem}
	.box-av span{padding-left: 0!important;}
	.box-av span.white-space-md{white-space: nowrap;}
	.form-newsletter{margin: 0;}
	.py-perso-news{padding-top: 1rem; padding-bottom: 1.5rem;}
	.footer-bottom{padding-top: 3rem;}
	.logo-footer img{max-width: 300px;}
}
@media only screen and (max-width:640px){
	.title-menu-t{font-size: 0.875rem}

}

@media only screen and (min-width: 576px){
	.toggle-m-c{display: block!important;height: 100%!important; transition: none!important;overflow: visible!important;}
	.title-menu-t.toggle-me{display: block!important;}
	.reseau-bloc h3 br{display: none;}
	.menu-content {max-width: 320px; }
}

@media only screen and (max-width: 575px){
	.bg-fixed-reseau{height: 10.2rem;}
	.hidden-575{display: none;}
	.adresse-newsletter{height: 2.825rem; padding-right: 145px;}
	.news-button{height:  2.825rem; font-size: 0.875rem; min-width: 7.725rem}
	.text-opti{font-size: 12px;}
	.menu-content{margin-bottom: 0px}
	.bloc-footer-menu {margin-left: -1rem;margin-right: -1rem;}
	.toggle-me {background: #EEEAEF;padding: 1.325rem  0.625rem;text-align: center;position: relative;border-bottom: 0.065rem solid #9EA6AD;display:block!important;color: #1F1B1C}
	.service-footer .toggle-me {border-bottom: none;}
	.toggle-me::after{ content: '\e8ca';display:block; position:absolute; right:1.2rem; top:50%;font-family: "fontello";   font-size: 1rem;transform:translate(0,-50%);-webkit-transform: translate(0, -50%);-ms-transform: translate(0, -50%);-o-transform: translate(0, -50%);-spec-transform: translate(0, -50%);color: #F98A8A;}
	.toggle-me.open{color: #F98A8A}
	.toggle-me.open::after{ content: '\e8cd';color: #F98A8A}
	.title-menu-t span{margin-bottom: 0;}
	.menu-content ul li a{font-size: 1rem}
	.title-menu-t{font-size: 1.18rem}
	.service-footer .title-menu-t{position: relative;padding-top:  0.825rem; padding-bottom: 0.825rem;}
	.service-footer .title-menu-t::after{ content: '\e8cd';display:block; position:absolute; right:1.2rem; top:50%;font-family: "fontello";   font-size: 1rem;transform:translate(0,-50%);-webkit-transform: translate(0, -50%);-ms-transform: translate(0, -50%);-o-transform: translate(0, -50%);-spec-transform: translate(0, -50%);color: #F98A8A;}
	.menu-content ul li{text-align:center; width:100%}

	.toggle-me + .toggle-m-c{display:none; padding:20px;margin-top: 0;text-align:center;border-bottom: 1px solid rgba(31,27,28,0.15);}
	.toggle-m-c >span,span.lib-none {display:none}
	.toggle-m-c{text-align:center;}
	.autre-footer-m {padding: 0;border: none;}
	.other-menu-bit{width:100%;    padding: 0}
	.box-av strong {padding-bottom: 0;height: 2.6rem;margin-bottom: 8px;display: flex !important;justify-content: center;align-items: self-end;}
	.box-av strong img {max-height: 100%;}
	.bloc-paiement-footer {max-width: 300px;margin-left: auto!important;margin-right: auto!important;}
	.bloc-newsletter{font-size: 1rem}
	.contact-footer{margin: auto;font-size: 1rem; padding-top: 18px; padding-bottom: 18px;max-width: 170px}
	.px-site-2-m{padding-right: 0.625rem; padding-left: 0.625rem}
	.ico-reseau img {width: 2.725rem;}
	.paymethod-hover img {height: 2.7rem;}
	.footer-bottom{padding-top: 0;}
	.box-av span{padding-top: 0;}
}
@media only screen and (max-width: 450px){
	.contact-footer{font-size: 0.938rem; max-width: 160px}
	.box-av {font-size: 0.7rem;}
	.service-footer {font-size: 1rem;}
}
@media only screen and (max-width: 390px){
	.avantage-footer {margin-left: -0.625rem;}

}
@media only screen and (max-width: 370px){
	.title-menu-t {font-size: 1.2rem}
	.menu-content ul li a{font-size: 0.938rem}

	.contact-footer{font-size: 0.875rem; max-width: 150px}
	.service-footer {font-size: 0.938rem;}

}

/**===================================
				CATEGORIE
=====================================*/

@media only screen and (max-width: 1300px){
	.categorie-top{padding-left: 10px;padding-right: 10px;}
}


@media only screen and (max-width: 1280px){

	.filtre-cat{padding-right: 25px}
	.filtre-c-bit{padding-right: 0.625rem; padding-left: 0.625rem;}

}
@media only screen and (max-width: 1023px){

	.object-img-categ {max-height: 16rem;overflow: hidden;}
	.categorie-img{padding-left: 1rem!important;padding-right: 1rem!important;padding-bottom: 1rem;}
}
@media only screen and (max-width: 991px){
	.filtre-c-bit select, .select-f{font-size: 0.875rem;}
	.title-categ {font-size: 2rem;}
	.lib-f-c{font-size: 0.875rem;}
}
@media only screen and (min-width: 768px){
	.content-fitre-categ {display: block!important;height: 100%!important;transition: none!important;overflow: visible!important;}

}
@media only screen and (max-width: 767px){
	.categorie-top{padding-left: 0;padding-right: 0;}
	.ss-categ-content{margin-left: 10px; margin-right: 10px;}
	.filtre-c-bit{width: 50%;}
	.categorie-filtre  {width: 100%;display: block;}

	.content-fitre-categ{margin: 0;padding-bottom: 0.625rem;}
	.max-categ2 {margin: 0.938rem auto 0 auto;padding-left: 4.5rem;}
	.categorie-detail-coll img {max-width: 200px;width: 100%;}
	.sscateg-lib{font-size: 38px; padding-bottom: 20px}
	.title-perso-big{font-size: 2.188rem;}
	.page-categorie {padding-left: 0;padding-right:0;}
	.ariane-box{padding-top: 12px; padding-bottom: 12px;}
	.filtre-cat{padding: 0; background: #f2f2f2; margin-top: 0}
	.lib-f-c {display: none!important;}
	.cell-facette{display: none;}

	.content-fitre-categ{display: flex;-moz-display: flex;-ms-display: flex;-o-display: flex;display: -ms-flex;background: #ffffff;height: 100%;padding: 35px 0;}
	.content-fitre-categ form{max-height: 90%; overflow-y: auto;overflow-x: hidden;}
	.cell-facette {position: fixed;width: 100%;height: 100%;z-index: 999;top: 0;left: 0;background: rgba(0,0,0,0.4);padding: 25px;display: none;}
	.ss-categ-link strong img{max-width: 140px; width: 100%;}
	.ss-categ-link span{font:700 0.813rem  "sofia-pro"; color: #1F1B1C;}
}

@media only screen and (max-width: 575px){
	.padding-mobile-ss{padding:0 35% 0 0;overflow: hidden;position: relative;margin-right: -2.5rem;}
	.padding-mobile-ss .swiper-container {overflow: visible;padding-left: 10px;}
	.title-categ {font-size: 2.1rem;}
	.filtre-c-bit{width: 100%;margin-bottom: 5px}
	.filtre-c-bit select, .select-f{font-size: 1rem;}
	.sscateg-lib-bit{font-size: 0.813rem}

	.res-filtre .filtre{padding:3px 5px;}
	.res-filtre .filtre a{padding-right: 0.938rem}
	.res-filtre .filtre a i{top: 2px}

	.res-filtre {padding-left: 0;padding-top: 0.938rem;padding-bottom: 0.938rem;}
	.link-free-delivery{font-size: 1.425rem;}
	.link-free-delivery span{font-size: 1.675rem;}
	.link-free-delivery em{font-size: 1rem;}
	.max-art{padding-right: 0}
	.max-categ2 {max-width: 350px;}
	.title-perso-big{font-size: 32px;}


}

/**===================================
				ARTICLE
=====================================*/

@media only screen and (max-width: 1280px){
	.article-top{padding-left: 0.625rem;padding-right: 0.625rem;}
	.article-bottom {padding-left: 0.625rem;padding-right: 0.625rem;}

}
@media only screen and (max-width: 1200px){
	.tab-article-content .nav-tabs .nav-link{font-size: 18px;}
	.article-bottom {padding-left: 0;padding-right:0;}
	.max-bottom-article {max-width: 100%;}

}
@media only screen and (max-width: 1100px){
	.tab-article-content .nav-tabs .nav-link{font-size: 1rem;padding-left: 5px;padding-right: 5px;}
	.article-left{width: 50%; position: relative}
	.article-right{width: 50%;}
}
@media only screen and (max-width: 991px){
	.art-img-content .select-container{display: inline-block}
	.tab-article-content .nav-tabs .nav-link{font-size: 0.875rem;}
	.contenuArticle {margin-top: 0;}
	.art-detail {margin-top: 0.938rem;}
	.article-right{padding-left: .5rem;}
	.body-table-perso {font-size: 0.813rem;}
	.border-table-perso .table-perso .card-header th {padding-left: 5px;padding-right: 5px;font-size: 12px;}
	.article-promo{font-size: 1.5rem;}
	/*.article-promo{font-size: 1.8rem;}*/
	.article-promo small{font-size: 0.8rem;}
}
@media only screen and (max-width: 767px){
	.article-top{padding-left: 0;padding-right:0;}
	.article-left{width: 100%;padding: 0; text-align: center;}
	.article-right{width: 100%; padding-left: .5rem;padding-right: .5rem;}
	.title-article-art{font-size: 1.5rem;}
	.ref-petit{font-size: 20px}
	.sstitle-article{font-size:28px}
	.big-img{width: 100%}
	.selectfitre-bit .bootstrap-select>.btn.dropdown-toggle{height: 60px;font-size: 17px;line-height: 40px;}
	.selectfitre-bit select.selectpicker.show-tick, .selectfitre-bit select{height: 50px;font-size: 17px;max-width: 450px;}
	.caractistique-article .selectfitre-bit label {font-size: 17px;}
	.selectfitre-bit{max-width: 450px; width: 100%}
	.selectfitre-bit:last-child {padding-bottom: 0!important;}
	.art-qte{font-size: 17px; padding-top: 0; padding-bottom:0;width: 100%;border-radius: 5px;-webkit-border-radius: 5px;-ms-border-radius: 5px;-o-border-radius: 5px;}
	.fiche-art-qty a {background-color: rgba(184,167,188,0.5);text-align: center;min-width: 90px;min-height: 2.75rem;padding-top: 0.25rem;}
	.fiche-art-qty a img{margin: auto;}
	.fiche-art-qty .label-qte {display: none;}
	.qte-moins-art {border-radius: 5px 0 0 5px;-webkit-border-radius: 5px 0 0 5px;-ms-border-radius: 5px 0 0 5px;-o-border-radius: 5px 0 0 5px;}
	.qte-plus-art{border-radius: 0 5px  5px 0;-webkit-border-radius: 0 5px  5px 0;-ms-border-radius: 0 5px  5px 0;-o-border-radius: 0 5px  5px 0;}
	.title-tab{font-size: 40px}
	.tab-contentBit{    padding-bottom: 60px;margin-bottom: 60px;}
	.btn-link.minw-verybig{width: 100%; display: block;padding-bottom: 20px; padding-top: 20px}
	.art-detail{margin-top: 0;}
	.body-index-change .art-detail{z-index: 999;}
	.art-retour{font-size: 0.875rem;}
	.art-qte .form-group-nomarg input.form-control{text-align: center; font-size: 0.875rem;height:2.75rem;}
	.art-top-desc{padding-bottom: 0; border-bottom: 0;}
	.pl-perso-conf{padding-left: 0.5rem!important;}
	.body-table-perso {font-size: 0.875rem;}
	.border-table-perso .table-perso .card-header th {font-size: 0.875rem;}
	.tab-article-content .tab-pane {display: block!important;opacity: 1!important;transition: none!important;margin-bottom: 0px;}
	.tab-article-content .nav-tabs {display: none;}
	.avantage-site{margin-top: 0;padding-bottom: 0;padding-top: 20px;text-align: left;}
	.tab-article-content .tab-content{margin-left: 0px;margin-right: 0px;padding-top: 0; padding-bottom: 1.625rem}
	.toggle-content-tab {display: none;background: #fff;padding: 15px;border-bottom: 1px solid #9EA6AD}
	.link-shop{height: 25px;padding-top: 0;}
	.font-step{font-size: 0.875rem;}
	.step-shipping.pt-2.pb-4 img {width: 3rem;}
	.border-table-perso .table-perso tbody{background-color: #F1EDF1;}
}

@media only screen and (max-width: 575px){
	.form-perso-content .champ-perso-bit input.form-control {max-width: 100%;}
	.tailleBit a{width: 48px; height: 48px; font-size: 22px;line-height: 47px;}
	.couleurBit a img {width: 37px;}
	.btn-panier{font-size: 1.435rem;padding-top: 1rem ; padding-bottom: 1rem;border-radius: 15px!important;-webkit-border-radius: 15px!important;-ms-border-radius: 15px!important;-o-border-radius: 15px!important;}
}
@media only screen and (max-width: 480px){
	.supp-img {margin-left:-0.625rem;margin-right:-0.625rem;}
	.supp-img img{width: 100%}
	.title-tab{font-size: 34px; margin-bottom: 20px}
	.tab-contentBit{    padding-bottom: 45px;margin-bottom: 40px;}

	.btn-link.minw-verybig{min-width: 200px;}
	.btn-link.minw-big-mob{min-width: 200px;}

}
@media only screen and (max-width: 420px){
	.article-right{ padding-left: 0.938rem;padding-right: 0.938rem;}
	.body-table-perso {font-size: 0.813rem;}
	.border-table-perso .table-perso .card-header th {font-size: 0.938rem;}
	.tailleBit a{width: 45px; height: 45px; font-size: 20px;line-height: 44px;}
	.couleurBit a img {width: 34px;}
}
@media only screen and (max-width: 390px){
	.ref-petit{font-size: 1rem}
	.art-qte{font-size: 0.938rem;}

	.prix-art-panier{font-size: 17px}
	.selectfitre-bit .bootstrap-select>.btn.dropdown-toggle{font-size: 0.938rem;height: 50px;line-height: 30px }
	.selectfitre-bit select.selectpicker.show-tick{font-size: 0.938rem;height: 50px;line-height: 30px}
	.caractistique-article .selectfitre-bit label{font-size: 0.938rem;}

	.article-right{ padding-left: 0.625rem;padding-right: 0.625rem;}
	.tailleBit a{width: 40px; height: 40px; font-size: 18px;line-height: 39px;}
	.couleurBit a img {width: 30px;}

}
@media only screen and (max-width: 370px){
	.title-tab{font-size: 32px}

	.art-retour{font-size: 0.813rem;}
	.prix-art-panier{font-size: 1rem}
	.body-table-perso {font-size: 12px;}
	.border-table-perso .table-perso .card-header th {font-size: 0.875rem;}
}
@media only screen and (max-width: 345px){

	.title-tab{font-size: 28px}
	.title-article-art{font-size: 28px}
	.ref-petit{font-size: 0.938rem}
	.sstitle-article{font-size:26px}
	.selectfitre-bit .bootstrap-select>.btn.dropdown-toggle{font-size: 0.875rem}
	.selectfitre-bit select.selectpicker.show-tick{font-size: 0.875rem}
	.caractistique-article .selectfitre-bit label{font-size: 0.875rem}

	.art-qte{font-size: 0.875rem;}
	input#qte {width: 100%;}
	.av-article{padding-left: 0!important;padding-right: 0!important;}
	.body-table-perso {font-size: 11px;}
	.border-table-perso .table-perso .card-header th {font-size: 0.813rem;}
}


/**===================================
			CONF
=====================================*/
@media only screen and (max-width: 991px){
	.width-auto-img {width: 70px;}
	.max-img-cart img {max-width: 50px;}
}

@media only screen and (min-width: 767px) {
	#box-validation #price-mobile {display: none !important;}
}

@media only screen and (max-width: 767px){
	.max-art-desc .title-article-art{display: none!important;}
	.tabpeso-conf {display: none;}
	.config-perso.advanced .personnalisation-bit{ opacity: 0; visibility: hidden;padding-right: 0;position: fixed;bottom: -100px;left: 0;width: 100%;margin-bottom: 0;-webkit-transition:all  0.3s linear;-moz-transition:all  0.3s linear;-o-transition:all  0.3s linear;transition: all  0.3s linear; -spec-transition:all  0.3s linear;z-index: 99;margin-right: 0;}
	.config-perso.non-advanced .personnalisation-bit{padding-right: 0;}
	.config-perso.advanced .personnalisation-bit.focus-mobile { opacity: 1; visibility: visible;bottom: 0;z-index: 1000;}
	.boxperso-article{position:relative;border-radius: 0;-webkit-border-radius: 0;-ms-border-radius: 0;-o-border-radius: 0;box-shadow: 0px 2px 6px 2px rgba(89,89,89,0.75);-webkit-box-shadow: 0px 2px 6px 2px rgba(89,89,89,0.75);-ms-box-shadow: 0px 2px 6px 2px rgba(89,89,89,0.75);-o-box-shadow: 0px 2px 6px 2px rgba(89,89,89,0.75);}
	.personnalisation-bit.ok-valid::after{display: none;}
	.widget-title, .personnalisation-bit >.title-veryss-regular {background: #fff; padding: 10px; /*display: none;*/}
	.ok-valid .boxperso-article input[type="text"]{border-color: #8CC63F!important;}
	.boxperso-article input[type="text"]{height: 3rem;}
	select.select-perso-s{height: 2.825rem;}
	.font-size-input{width: 8rem;}
	.font-size-input >a{height: 2.825rem;width: 2.5rem;}
	.boxperso-article input.input-size{height: 2.825rem;}
	.label-input-content{width: 2.525rem;}
	/*.label-input-content:not(.wrap-line-space){width: 2.525rem;}*/
	.label-input-content span svg {max-width: 2.525rem;}
	.color-flex{padding-top: 0.6rem; padding-bottom: 0.6rem;}
	.max-box-w{max-width: 2.525rem;}
	.popup-inner {width: 100%;max-width: 100%;max-height: 85vh;}
	.popup {top: auto;bottom: 0;height: auto;box-shadow: 0px 2px 6px 2px rgb(89 89 89 / 75%);
		-webkit-box-shadow: 0px 2px 6px 2px rgb(89 89 89 / 75%);
		-ms-box-shadow: 0px 2px 6px 2px rgba(89,89,89,0.75);
		-o-box-shadow: 0px 2px 6px 2px rgba(89,89,89,0.75);}
	.btn-slide-next.library-next{right: -20px;}
	.btn-slide-next.library-prev{left: 10px;}
	.padding-visible-library >.position-relative{padding-right: 15%;}
	.padding-visible-library::after{right: 0;}
	.ddt.list-text-colors, .ddt.list-stroke-colors, .ddt.list-shadow-colors {width: 100%;position: fixed;left: 0px;bottom: 0px;}
	.ddt.list-fonts {width: 100%;position: fixed;left: 0px;bottom: 0px;}
	#box-validation #btn-back, .btn-back-wrap #btn-back {flex-direction: column; padding-top: 5px;}
	.btn-back-wrap #btn-back {margin-top: 10px; line-height: 0.5}
	#box-validation #btn-back::before, .btn-back-wrap #btn-back::before {content: url("../images/ico_arrow_left_white.png"); height: 30px; scale: 0.7;}
	#box-validation #btn-next-add-panier .btn-next-add-panier-mobile::after {content: url("../images/ico_panier_white.png"); margin-left: 5px;scale: 0.7;height: 30px;transform: translateY(-7px);}
	#box-validation #btn-next-add-panier .btn-next-add-panier-mobile {display: flex;align-items: center;justify-content: center;}
	#box-validation #content-qte-tmp {height: unset;}
	#box-validation #price-mobile {display: flex; align-items: center; font-size: 1.0rem;padding: 5px 2px;text-align: center;margin-left: 10px}
	/*#box-validation #price-mobile {display: flex; align-items: center; font-size: 0.3rem;padding: 5px 2px;text-align: center;margin-left: 10px}*/
	#box-validation #price-mobile > strong, #box-validation #price-mobile span.line-through {font-size: 1rem; display: block;}
	#box-validation #btn-next-add-panier {margin-left: 10 !important;}
	/*.prix-art-panier {display: none;}*/
	.fonts{
		height: 2.825rem;
	}
	.stroke-color, .shadow-color{
		width: 2.52rem;
		align-items: stretch;
		display: flex;
	}
	.stroke-color .dds, .shadow-color .dds {
		height: auto;
		width:100%;
	}
	.stroke-color .dds label, .shadow-color .dds label{
		width:100%;
	}

	.price-mobile-wrap{
		display: flex;
		flex-direction: column;
		line-height: 0.9rem;
	}
}

@media only screen and (max-width:575px){
	.boxperso-article input[type="text"]{height: 2.825rem;}
	select.select-perso-s{height: 2.6rem;}
	.font-size-input >a{height: 2.5rem;}
	.boxperso-article input.input-size{height: 2.5rem;}

	.table-perso td,.table-perso th{padding-left: 5px; padding-right: 5px}
	table#sylius-cart-items td:last-child{min-width: 40px; padding-left: 5px; padding-right: 5px}
	.width-auto-img {width: 60px;}
	.max-img-cart img {max-width: 45px;}
	.hidden-sm-table{display: none!important}
	.qte-cart{width: 130px}
	.max-total-cart{padding:0.938rem;max-width: 300px}
	.fontsize-mobilesm-cart{font-size: 17px}
	.step-panier{font-size: 33px;}
	.stepBox-bit{padding-left:30px; padding-right:15px;}
	.stepBox-bit i::before{width: auto;}
	.fonts{
		height: 2.5rem;
	}
}

@media only screen and (max-width:480px){
	#page-configurateur .max-container-3{padding-left: 0.625rem; padding-right: 0.625rem}

}
@media only screen and (max-width: 420px){

	table#sylius-cart-items td{font-size: 0.875rem}
	.fontsize-mobilesm-cart{font-size: 1rem}
	.step-panier{font-size: 28px;}
	.stepBox-bit{padding-left:25px; padding-right:10px;}
	.stepBox-bit i::before{font-size: 22px;}
}

@media only screen and (max-width: 393px) {
	#box-validation #btn-next-add-panier {margin-left: 0; max-width: 100%; font-size: 1rem; margin-right: -5px;}
	#box-validation #btn-next-add-panier .btn-next-add-panier-mobile::after {margin-left: -5px;scale: 0.5; height: 25px; transform: translateY(-10px)}
}

@media only screen and (max-width: 380px){
	.title-configbit{font-size: 22px}
	table#sylius-project-items td:last-child{min-width: 40px}
	.max-img-project img {max-width: 40px;}
	.width-auto-img {width: 50px;}
	.max-img-cart img {max-width: 40px;}
	.fontsize-mobilesm-cart{font-size: 0.875rem}
	table#sylius-cart-items td{font-size: 0.813rem}
}

@media only screen and (max-width: 350px){
	.fontsize-mobilesm-cart{font-size: 0.813rem}
	.step-panier{font-size: 23px;}
	.stepBox-bit{padding-left:20px; padding-right:5px;}
	.stepBox-bit i::before{font-size: 20px;}
}

@media only screen and (max-width: 325px) {
	#box-validation #btn-next-add-panier .btn-next-add-panier-mobile {flex-direction: column-reverse;}
	#box-validation #btn-next-add-panier .btn-next-add-panier-mobile::after {height: 15px; transform: translateY(-20px)}
	#box-validation #btn-next-add-panier {padding-bottom: 5px;}
}
/*=====================================
                PANIER
=======================================*/
.lib-mobile{display:none; font-size:12px;  vertical-align:middle}
.visible-680{display:none}
@media only screen and (max-width:1200px) {
	.media.align-items-center img {max-width:70px;}
	.px-perso-cart {padding-left:10px;padding-right:10px;}
	.card-header.px-perso-cart{font-size: 13px;}
}

@media only screen and (max-width:767px) {
	.body-sylius_shop_cart_summary .page-content{padding-bottom: 30px;}
	#list_points .point_item {flex-direction: column;}
}

@media only screen and (max-width:600px) {
	.table-perso .card-header th, .table-perso .card-header {font-size: 0.938rem;padding-left: 5px; padding-right: 5px;line-height: normal;padding-top: 8px; padding-bottom: 8px;}
	.card-header.px-perso-cart{font-size: 0.938rem;padding-left: 5px; padding-right: 5px;line-height: normal;padding-top: 8px; padding-bottom: 8px;}
	.table-perso tbody td{font-size: 0.875rem;padding-left: 5px; padding-right: 5px;    padding-top: 5px;padding-bottom: 5px;}
	.table-perso .sylius-total{font-size: 0.938rem;}
	.sylius-product-name{font-size: 0.938rem;}
	.table-perso .sylius-quantity{max-width: 60px;}
	.title-h2{font-size: 20px;}
	.btn-perso-c{font-size: 0.938rem;}
	.btn-dark-perso{font-size: 0.938rem;}
	.total-label{font-size: 0.875rem;}
	.btn-paie{font-size: 22px;}
	.cart-container.article-top {padding-left: 5px; padding-right: 5px;}
}

@media only screen and (max-width:575px) {
	.cart-container.article-top {padding-left: 0; padding-right: 0;}
	.table-perso span.sylius-quantity input.form-control{font-size: 0.813rem;width: 50px;background:none;height: 30px;}
	.title-h2{font-size: 18px;}
	.input-perso-content input.form-control{font-size: 0.813rem;}
	.cart-container .row.mb-5{margin-left: -5px; margin-right: -5px;}
	.cart-container .row.mb-5>div{padding-left: 5px; padding-right: 5px;}
	#sylius-cart-grand-total, .total-cart-summ{font-size: 19px;}
    .cartw-uniprice {min-width: 100%; padding-bottom: 0!important;}
	.cartw-uniprice > .px-3 {padding-left: 0!important; display: flex;}
    .cartw-uniprice > .px-3 > div:first-child {margin-right: 10px;}
    .cartw-uniprice > .px-3 > .prix-unit {font-size: 1rem !important;}
	.absolute-suppr {position: absolute;right: 10px;top:30%;}
	.media.align-items-center img {max-width: 6rem;}
	.cartw-qty {max-width: 8rem;flex: 0 0 8rem;}
	.contenu-panier-qty input {height: 53px;padding-left: 5px;}
	#sylius-cart-items span.sylius-quantity{max-width: 8rem;}
	.contenu-panier-qty .qte-plus {top: 5px;}
	.contenu-panier-qty .qte-moins {bottom: 5px;}
	.cartw-total .font-20 {font-size: 1.325rem!important;line-height: 100%;}
	.cartw-total  .font-12{font-size: 1rem!important;}
	.max-content-mobile {max-width: max-content;margin-left: auto;}
	.height-btn {height: 45px;}
	.input-code-promo.input-perso-content input.form-control{height: 45px!important;}
	.panier-summary-bloc .btn-fixed-content {position: fixed;left: 0;bottom: 0;width: 100%;z-index: 99;background: #fff;padding: 12px;border-top: 1px solid #ccc;}
}

@media only screen and (max-width:400px) {
	.table-perso .card-header th, .table-perso .card-header{font-size: 0.813rem}
	.table-perso .sylius-total{font-size: 0.813rem;}
	.sylius-product-name{font-size: 0.875rem;}
	.btn-perso-c{font-size: 0.875rem;}
	.btn-dark-perso{font-size: 0.875rem;}
	.total-label{font-size: 0.875rem;}
	#sylius-cart-grand-total, .total-cart-summ{font-size: 18px;}
	.btn-paie{font-size: 20px;}
	.table-perso span.sylius-quantity input.form-control{width: 40px;}
}
@media only screen and (max-width:370px) {
	.contenu-panier-qty input {height: 48px;}
}
/***********checkout************/
@media only screen and (max-width:820px) {
	.content-adress .steps-item{padding-left: 5px; padding-right: 5px;line-height: 50px;}
	.content-adress .steps .steps-item .steps-title{font-size: 18px;}
	.content-adress .steps .steps-icon{font-size: 18px;}
	.form-perso-content input.form-control,.form-perso-content select.form-control {font-size: 0.813rem;}
}
@media only screen and (max-width:640px) {
	.content-adress .steps-item{line-height: 40px;}
	.content-adress .steps .steps-item .steps-title{font-size: 1rem;}
	.content-adress .steps .steps-icon{font-size: 1rem;padding-right: 0.625rem;}
	.content-adress .svg-inline--fa{vertical-align: middle;}
	.card-body.form-perso-content {padding-left: 0.625rem;padding-right: 0.625rem;}
	.item-content-ship img {max-width: 7rem;}
}
@media only screen and (max-width:575px) {
	.pl-perso-checkout-sm {padding-left: 0;padding-right: 0;}
}
@media only screen and (max-width:480px) {
	.content-adress .steps-item{line-height: 38px;}
	.content-adress .steps .steps-item .steps-title{font-size: 0.938rem;}
	.content-adress .steps .steps-icon{font-size: 0.938rem;}
	.form-perso-content input.form-control,.form-perso-content select.form-control {font-size: 0.725rem;}
	.dropdown.address-book-select{  font-size: 0.725rem;}
	.item-content-ship img {max-width: 6rem;}
}
@media only screen and (max-width:400px) {
	.content-adress .steps .steps-item .steps-title{font-size: 0.813rem;}
	.content-adress .steps .steps-icon{font-size: 0.813rem;padding-right: 5px;}
}
@media only screen and (max-width:360px) {
	.content-adress .steps .steps-item .steps-title{font-size: 12px;}
	.content-adress .steps .steps-icon{font-size: 12px;margin-top: -5px;}
}
@media only screen and (max-width:330px) {
	.content-adress .steps .steps-item .steps-title{font-size: 11px;}
	.content-adress .steps .steps-icon{font-size: 11px;padding-right: 2px;}
}

/*=================================
        CLIENT
===================================*/
@media only screen and (max-width: 1200px){
	.container.my-0.page-account-content {max-width: 100%;}
}

@media only screen and (max-width: 1140px){
	.nom-user strong{font-size:19px}
	.client-cmd-show table#sylius-checkout-subtotal td{font-size: 0.7rem;}
	/******ADRESSE******/
	.list-adress .btn-outline-secondary {    font-size: 11px;padding-left: 5px;padding-right: 5px;}
	.content-bottom-order .table-perso .total-label{font-size: 0.813rem;}
	.list-item-bit a{font-size: 0.813rem;}
	.title-compte-client .title-medium,.content-bottom-order #order-invoices h3#shipping-state{font-size:2.9rem;}
}

@media only screen and (max-width: 1080px){
	.table-histo-account.table-perso .card-header th, .table-histo-account.table-perso td {font-size: 12px;padding-left: 2px;padding-right: 2px;}
	.table-histo-account.table-perso .action-table-perso .btn-link{font-size:11px;padding-left: 5px;padding-right: 5px}
	#order-invoices table td, #order-invoices table th {font-size: 12px;padding-left: 5px;padding-right: 5px;}
	.p-3.content-bottom-order .table-perso .col-12.col-lg-4 {padding-left: 0;}
	.table-histo-account.table-perso .sylius-table-column-shippingAddress {width: 80px;white-space: normal;}
	.content-bottom-order .table-perso .total-label{font-size: 12px;}

	.title-med-account{font-size:  2.725rem;}
}
@media only screen and (max-width: 991px){
	.menu-left .list-group-item.bg-light.rounded-0.border-0 {padding-left: 5px;padding-right: 5px;}
	.nom-client-p.py-3.px-2 {padding-left: 0!important;padding-right: 0!important;}
	.list-item-bit a{padding-left: 45px; padding-right: 0.625rem;font-size: 12px;background-position-x: 0.813rem ;}
	.list-item-bit a.lien-save{background-position-x:7px}
	.list-item-bit a::after{left: 45px}
	.txt-bienvenu{font-size:1.2rem}
	.navbar-nav + .d-md-none {display: none;}
	.title-compte-client .title-medium,.content-bottom-order #order-invoices h3#shipping-state{font-size: 2.5rem;}
	.title-menu-account .title-medium{font-size: 2.5rem;}
	.title-compte-client p.mb-4 {margin-bottom:0.938rem!important}
	/******ADRESSE******/
	.list-adress .col-12.col-lg-6.mb-3 {border-right: none!important;border-bottom: 1px solid #ccc!important;}
	.col-12.col-md-4.left-dash {  padding-right: 0;}
	.image-bienvenu img{max-width: 150%;}
	#order-invoices table a i.icon.download {	background-size: 0.938rem;width: 0.938rem;height: 0.938rem;}
	.p-3.content-bottom-order .table-perso .col-12.col-lg-4 {padding-left: 0.938rem;}
	.table-histo-account.table-perso .card-header th, .table-histo-account.table-perso td {font-size:0.625rem;}
	.table-histo-account.table-perso .action-table-perso .btn-link{font-size:0.625rem;}
}

@media only screen and (max-width: 767px){
	.page-account-content .row.overflow-hidden {position: relative;}
	.col-12.col-md-4.sylius_shop_account_dashboard {overflow: hidden;padding-left: 0;}
	.title-compte-client .title-medium,.content-bottom-order #order-invoices h3#shipping-state{padding-left: 45px}
	.image-bienvenu h1{display: none;}
	.sylius_shop_account_dashboard  +  .hidden-dash {display: none;}
	.image-bienvenu img{top:-19%}
	.nom-client-p {padding: 0!important;margin-bottom: 0.625rem;margin-top: -55px;z-index: 9;position: relative;text-align: center;}
	.nom-user {display: block;width: 100%;margin-top: 0.625rem;padding: 0 0.625rem 0 0.625rem;}

	.menu-left {display: none;overflow:visible!important;}
	.sylius_shop_account_dashboard .menu-left{display: block;}
	.menu-client >.row>.col-12{padding-left: 8px;padding-right: 8px;}

	/******ADRESSE******/
	.list-adress button.btn.btn-link.btn-valider.rounded-0.border-0.text-uppercase.text-white {font-size: 0.938rem;padding: 0.938rem;}
	.list-adress .card-body{padding-left: 0.625rem; padding-right: 0.625rem;}
	.title-compte-client .title-medium{font-size: 2.3rem;padding-top: 1rem;padding-bottom: 1rem;}
	.content-bottom-order #order-invoices h3#shipping-state{font-size: 2.3rem;}

	.image-bienvenu img {max-width: 150%;height: auto!important;}
	.page-sylius_shop_account_dashboard{position: relative;margin-top: 0!important;}
	.nom-user strong {font-size: 23px;}
	.page-instit-img h2{font-size: 1.2rem;}

	.table-histo-account.table-perso .card-header th, .table-histo-account.table-perso td {font-size:12px;}
	.table-histo-account.table-perso .action-table-perso .btn-link{font-size:12px;}
	.content-bottom-order .table-perso .total-label{font-size: 0.938rem;}
	.list-item-bit a{font-size: 0.875rem;}
}

@media only screen and (max-width: 600px){
	.content-bottom-order .table-perso .card-header th {padding-left: 5px;padding-right: 5px;}
	.content-bottom-order .table-perso .sylius-product-name {font-size: 0.938rem;}
}

@media only screen and (max-width: 575px){
	.table-histo-account .sylius-table-column-shippingAddress {display: none!important;}
	.page-sylius_shop_account_order_show th.sylius-table-column-subtotal.text-right.border-bottom-0 {width: 95px;}
	.form-perso-content .custom-file-label::after{min-width: 120px; font-size: 12px}
	.form-perso-content  .custom-file-label{padding-right: 125px}
	.title-med-account{font-size:  2.5rem;}
}

@media only screen and (max-width: 480px){
	.table-histo-account	.action-table-perso>a {display: block;margin-bottom: 1px;}
	.title-compte-client .title-medium,.content-bottom-order #order-invoices h3#shipping-state{font-size: 2rem;}
	.content-bottom-order .table-perso .card-header th{font-size: 0.813rem;}
	.page-sylius_shop_account_order_show th.sylius-table-column-price.text-center.border-bottom-0 {display: none;}
	.page-sylius_shop_account_order_show table#sylius-order td:nth-child(2){display: none;}
	.content-bottom-order .table-perso .sylius-product-name {font-size: 0.813rem;}
	.content-bottom-order .table-perso .card-header,.content-bottom-order #order-invoices table th{font-size: 0.813rem;}
	.page-instit-img h2{font-size: 1rem;}
	.table-histo-account.table-perso .card-header th, .table-histo-account.table-perso td {font-size:11px;}
	.table-histo-account.table-perso .action-table-perso .btn-link{font-size:11px;}
}

@media only screen and (max-width: 380px){
	.table-histo-account  .sylius-table-column-state {display: none;}
	.ariane-box .breadcrumb-item+.breadcrumb-item:before{padding-right: 2px;}
	.ariane-box .breadcrumb-item+.breadcrumb-item{padding-left: 2px;}
	.content-bottom-order .table-perso .total-label{font-size: 0.813rem;}
	.list-item-bit a{font-size: 0.813rem;}
} 

